import AddIcon from '@mui/icons-material/Add'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useTranslations } from '~/src/hooks/useTranslations'
import RoutesEnum from '~/src/router/enums/routes.enum'
import Button from '~/src/components/mui-wrappers/buttons/Button'

const AddressAddButton = () => {
  const { t } = useTranslations()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()

  const handleOnAddAddress = () => {
    push(resolve(`${RoutesEnum.PROFILE}/${RoutesEnum.ADDRESS}`))
  }

  return (
    <Button
      color='primary'
      variant='contained'
      onClick={handleOnAddAddress}
      startIcon={<AddIcon sx={{ color: 'accent.main' }} />}
      sx={{
        borderRadius: 'var(--btn-border-radius)'
      }}
    >
      {t('profile.addAddress')}
    </Button>
  )
}

export default AddressAddButton
