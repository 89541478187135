import { Address } from '@eo-storefronts/eo-core'
import Title from '~/src/components/mui-wrappers/Title'
import Card from '~/src/components/mui-wrappers/Card'
import AddressEditButton from '~/src/components/profile/account/addresses/AddressEditButton'
import AddressCardDetails from '~/src/components/profile/account/addresses/AddressCardDetails'

interface Props {
  address: Address,
}

const AddressCard = ({ address }: Props) => (
  <Card
    sx={{
      position: 'relative',
      borderRadius: '1em',
      p: 2,
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText',
      display: 'flex',
      flexDirection: 'column',
      gap: 1
    }}
    elevation={0}
  >
    <Title
      variant='h6'
      className='eo-ellipsis'
    >
      {address.description}
    </Title>
    <AddressCardDetails address={address} />
    <AddressEditButton address={address} />
    {/*<FavouriteAddressButton address={address}/>*/}
  </Card>
)

export default AddressCard
