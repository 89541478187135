import RoutesEnum from '~/src/router/enums/routes.enum'
import Link from '~/src/components/mui-wrappers/Link'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Address } from '@eo-storefronts/eo-core'

interface Props {
  address: Address,
}

const AddressEditButton = ({ address }: Props) => {
  const { t } = useTranslations()

  return (
    <Link
      href={`${RoutesEnum.PROFILE}/${RoutesEnum.ADDRESS}/${address.id}`}
      sx={{
        color: 'secondary.contrastText'
      }}
    >
      {t('profile.address.editAddress')}
    </Link>
  )
}

export default AddressEditButton
