import { Typography } from '@mui/material'
import { Address } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { LANGUAGE_STATE } from '~/src/stores/lang'

interface Props {
  address: Address,
}

const AddressCardDetails = ({ address }: Props) => {
  const lang = useEoValue(LANGUAGE_STATE)

  return (
    <div>
      <Typography
        variant='body2'
        className='eo-ellipsis'
      >
        {address.street}
        {address.bus && ` | ${address.bus}`}
      </Typography>

      <Typography
        variant='body2'
        className='eo-ellipsis'
      >
        {`${address.zipCode} ${address.locality} - ${address.country?.name[lang]}`}
      </Typography>
    </div>
  )
}

export default AddressCardDetails
